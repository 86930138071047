import React, { useState } from 'react';

import type { UnAuthItemsProps } from '../unauth-items';
import SettingsMenuContent from './settings-menu-content/settings-menu-content';

import { DropdownMenu, IconButton, useMediaQuery } from 'cryptomus-aurora-kit';

import BurgerMenuContainer from 'ui/burger-menu-container/burger-menu-container';

import { useTextDirection } from 'hooks/use-text-direction';

import SettingsIcon from 'public/icons/header/Adjustments_Horizontal.svg';

import styles from './settings-menu.module.scss';

const SettingsMenu: React.FC<UnAuthItemsProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const isTablet = useMediaQuery('tablet');

  const direction = useTextDirection();

  return isTablet ? (
    <>
      <IconButton appearance='header' onClick={() => setIsOpen(!isOpen)}>
        <SettingsIcon />
      </IconButton>
      <BurgerMenuContainer
        align='right'
        direction={direction}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <SettingsMenuContent burger closeMenu={() => setIsOpen(false)} />
      </BurgerMenuContainer>
    </>
  ) : (
    <DropdownMenu
      isOpen={isOpen}
      maxHeight={632}
      placement='bottom-end'
      setIsOpen={setIsOpen}
      width={336}
      parent={() => (
        <IconButton
          appearance='header'
          className={styles.settings_btn}
          // eslint-disable-next-line jsx-a11y/tabindex-no-positive
          tabIndex={1}
          onClick={() => setIsOpen(!isOpen)}
        >
          <SettingsIcon />
        </IconButton>
      )}
    >
      <SettingsMenuContent closeMenu={() => setIsOpen(false)} />
    </DropdownMenu>
  );
};

export default SettingsMenu;
