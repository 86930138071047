'use client';

import React, { useEffect, useMemo, useState } from 'react';

import { BURGER_MENU_LIST_DATA } from '../header.data';
import type {
  IHeaderAdaptiveBasicProps,
  INavListDataItem,
} from '../header.types';
import type {
  ISubItem,
  mainMenuItem,
} from '../navigation/nav-list/nav-item/nav-item.types';
import BurgerHeader from './burger-header/burger-header';
import BurgerMenuItem from './burger-menu-item/burger-menu-item';

import clsx from 'clsx';
import {
  Button,
  IconButton,
  useMediaQuery,
  useTextDirection,
} from 'cryptomus-aurora-kit';
import { useTranslations } from 'next-intl';

import BurgerMenuContainer from 'ui/burger-menu-container/burger-menu-container';
import DropdownList from 'ui/dropdown-list/dropdown-list';

import BurgerIcon from 'public/icons/header/burger-icon.svg';

import styles from './burger-menu.module.scss';

const BurgerMenu: React.FC<IHeaderAdaptiveBasicProps> = ({
  isLongLang = false,
  isAuthorized = false,
  align = 'left',
}) => {
  const t = useTranslations('layout.header');

  const dir = useTextDirection();

  const [openBurger, setOpenBurger] = useState<boolean>(false);
  const [openSubMenu, setOpenSubMenu] = useState<ISubItem | null>(null);
  const isLaptopC = useMediaQuery('laptopC');

  const getTitle = (title: string) =>
    ['More', 'Earn'].includes(title) || title?.length > 4 ? t(title) : title;

  const getSubItem = (title: string, item: INavListDataItem) => {
    if (!item.some((subItem) => subItem.subItems))
      return {
        el: (
          <div className={styles.sub_list}>
            {item.map((subItem) => (
              <BurgerMenuItem
                key={subItem.title}
                hasChildren={false}
                icon={subItem.icon}
                link={subItem.link}
                title={getTitle(subItem.title)}
                description={
                  subItem.description ? t(subItem.description) : undefined
                }
              />
            ))}
          </div>
        ),
        title: getTitle(title),
      };

    return {
      subItem: item.map((subItem) => ({
        el: (
          <BurgerMenuItem
            hasChildren={!!subItem.subItems}
            icon={subItem.icon}
            link={subItem?.link}
            title={getTitle(subItem.title)}
            description={
              subItem?.description ? t(subItem.description) : undefined
            }
          />
        ),
        subItem: subItem?.subItems
          ? {
              el: (
                <div className={styles.sub_list}>
                  {subItem.subItems.map((subItem) => (
                    <BurgerMenuItem
                      key={subItem.title}
                      hasChildren={false}
                      icon={subItem.icon}
                      link={subItem.link}
                      title={getTitle(subItem.title)}
                      description={
                        subItem.description ? t(subItem.description) : undefined
                      }
                    />
                  ))}
                </div>
              ),
              title: getTitle(subItem.title),
            }
          : undefined,
        title,
      })),
      title: getTitle(title),
    };
  };

  const mainListBase: mainMenuItem[] = [
    {
      el: <BurgerHeader closeBurger={() => setOpenBurger(false)} />,
    },
    ...BURGER_MENU_LIST_DATA.map((item) => ({
      el: (
        <BurgerMenuItem
          hasChildren={!!item?.items}
          icon={item.icon}
          link={item.link}
          title={getTitle(item.title)}
        />
      ),
      subItem: item.items ? getSubItem(item.title, item.items) : undefined,
    })),
  ];

  const mainList: mainMenuItem[] = useMemo(
    () =>
      isAuthorized
        ? mainListBase
        : [
            ...mainListBase,
            {
              el: (
                <div className={styles.get_started_btn}>
                  <Button
                    appearance='primary'
                    as='a'
                    href='#'
                    size='m'
                    type='external'
                  >
                    {t('Get started')}
                  </Button>
                </div>
              ),
            },
          ],
    /* eslint-disable react-hooks/exhaustive-deps */
    [mainListBase]
  );

  useEffect(() => {
    if (!isLaptopC) setOpenBurger(false);
  }, [isLaptopC]);

  return (
    <>
      <div
        className={clsx(styles.burger_btn_wrapper, {
          [styles.long_lang]: isLongLang,
        })}
      >
        <IconButton
          active={openBurger}
          appearance='header'
          className={styles.burger_btn}
          size='small'
          onClick={() => setOpenBurger(!openBurger)}
        >
          <BurgerIcon />
        </IconButton>
      </div>
      <BurgerMenuContainer
        align={align}
        direction={dir}
        isOpen={openBurger}
        setIsOpen={setOpenBurger}
        onCloseEvent={() => setOpenSubMenu(null)}
      >
        <DropdownList
          burger
          direction={dir}
          mainLinks={mainList}
          openSubMenuList={openSubMenu}
          setOpenSubMenuList={setOpenSubMenu}
        />
      </BurgerMenuContainer>
    </>
  );
};

export default BurgerMenu;
