import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/production/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/production/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["ReCaptcha"] */ "/app/node_modules/next-recaptcha-v3/lib/ReCaptcha.js");
;
import(/* webpackMode: "eager", webpackExports: ["ReCaptchaContext","ReCaptchaProvider","useReCaptchaContext"] */ "/app/node_modules/next-recaptcha-v3/lib/ReCaptchaProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["useReCaptcha"] */ "/app/node_modules/next-recaptcha-v3/lib/useReCaptcha.js");
;
import(/* webpackMode: "eager", webpackExports: ["withReCaptcha"] */ "/app/node_modules/next-recaptcha-v3/lib/withReCaptcha.js");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalApiEventEmitterInitializer"] */ "/app/src/_app/providers/api-event-emitter-initializer.ts");
;
import(/* webpackMode: "eager", webpackExports: ["CustomNProgressProvider"] */ "/app/src/_app/providers/custom-nprogress-provider/custom-nprogress-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/_app/providers/i18n/i18n-client-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/_app/providers/react-query-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RecaptchaV2Provider"] */ "/app/src/_app/providers/recaptcha-v2-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/_app/providers/ref-cookie-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/_app/providers/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToastsProvider"] */ "/app/src/_app/providers/toasts-provider/toasts-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UtmTagsProvider"] */ "/app/src/_app/providers/utm-tags-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/_app/styles/globals.scss");
;
import(/* webpackMode: "eager" */ "/app/src/_app/styles/variables.scss");
;
import(/* webpackMode: "eager" */ "/app/src/_app/styles/index.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/app/src/components/footer/footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/header/index.tsx");
