import React, { useState } from 'react';

import { MenuItem } from './menu-item/menu-item';

import clsx from 'clsx';
import { DropdownMenu, Typography } from 'cryptomus-aurora-kit';
import { useTranslations } from 'next-intl';

import { linkMenuList } from 'components/header/header.data';
import type { IHeaderAdaptiveBasicProps } from 'components/header/header.types';

import MenuIcon from 'public/icons/header/Grid.svg';

import styles from './links-menu.module.scss';

const LinksMenu: React.FC<IHeaderAdaptiveBasicProps> = ({
  isLongLang = false,
}) => {
  const t = useTranslations('layout.header');
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Typography
        className={styles.menu_title}
        fontWeight={500}
        variant='small_button'
      >
        CRMS
      </Typography>
      <DropdownMenu
        isOpen={open}
        parentClassName={styles.dropdownTrigger__wrapper}
        setIsOpen={setOpen}
        width={335}
        parent={() => (
          <button
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex={1}
            className={clsx(styles.menu_btn, {
              [styles.open]: open,
              [styles.long_lang]: isLongLang,
            })}
            onClick={() => setOpen(!open)}
          >
            <MenuIcon />
          </button>
        )}
      >
        <div className={styles.links}>
          {linkMenuList.map((item) => (
            <MenuItem
              key={item.title}
              description={item?.description ? t(item.description) : undefined}
              icon={item.icon}
              link={item?.link}
              title={t(item.title)}
              onSelect={() => setOpen(false)}
            />
          ))}
        </div>
      </DropdownMenu>
    </>
  );
};

export default LinksMenu;
