'use client';

import { useEffect } from 'react';
import { useSearchParams } from 'next/navigation';

import Cookies from 'js-cookie';
import type { PropsWithChildren } from 'react';

export const UtmTagsProvider = ({ children }: PropsWithChildren) => {
  const searchParams = useSearchParams();

  const utmTags = [
    'utm_source',
    'utm_content',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'ref',
    'click_id',
    'cpa',
    'user_id',
    'client_id',
  ];

  useEffect(() => {
    utmTags.forEach((tag) => {
      const paramValue = searchParams?.get(tag);

      if (paramValue) {
        Cookies.set(tag, paramValue, {
          domain: 'cryptomus.com',
          expires: 7,
        });
      } else if (Cookies.get(tag) === 'undefined') {
        Cookies.remove(tag, { domain: 'cryptomus.com' });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return <>{children}</>;
};
