'use client';

import React, { useEffect, useState } from 'react';

import type { Placement } from '@floating-ui/react';
import clsx from 'clsx';
import {
  DropdownMenu,
  IconButton,
  useMediaQuery,
  useTextDirection,
} from 'cryptomus-aurora-kit';

import BurgerMenuContainer from 'ui/burger-menu-container/burger-menu-container';

import styles from './control-item.module.scss';

interface IControlItemProps {
  content: (closeMenu: () => void, burger?: boolean) => JSX.Element;
  icon: JSX.Element;
  isOpen: boolean;
  placement: Placement;
  setIsOpen: (value: boolean) => void;
  width: number;
  counter?: number;
  hideInMobile?: boolean;
  hideInTablet?: boolean;
}

const ControlItem: React.FC<IControlItemProps> = ({
  isOpen,
  setIsOpen,
  icon,
  content,
  counter,
  placement,
  width,
  hideInMobile = false,
  hideInTablet = false,
}) => {
  const [openBurger, setOpenBurger] = useState<boolean>(false);

  const isTablet = useMediaQuery('tablet');

  const dir = useTextDirection();

  useEffect(() => {
    setIsOpen(false);
    setOpenBurger(false);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [isTablet]);

  const closeMenu = () => setOpenBurger(false);

  return (
    <div
      className={clsx({
        [styles.hide_in_mobile]: hideInMobile,
        [styles.hide_in_tablet]: hideInTablet,
      })}
    >
      {isTablet ? (
        <>
          <IconButton
            active={isOpen}
            appearance='header'
            className={styles.control_btn}
            counter={counter}
            size='small'
            onClick={() => setOpenBurger(!openBurger)}
          >
            {icon}
          </IconButton>
          <BurgerMenuContainer
            align='right'
            direction={dir}
            isOpen={openBurger}
            setIsOpen={setOpenBurger}
          >
            {content(closeMenu, true)}
          </BurgerMenuContainer>
        </>
      ) : (
        <DropdownMenu
          isOpen={isOpen}
          placement={placement}
          setIsOpen={setIsOpen}
          width={width}
          parent={() => (
            <IconButton
              active={isOpen}
              appearance='header'
              className={styles.control_btn}
              counter={counter}
              size='small'
              onClick={() => setIsOpen(!isOpen)}
            >
              {icon}
            </IconButton>
          )}
        >
          {content(closeMenu, false)}
        </DropdownMenu>
      )}
    </div>
  );
};

export default ControlItem;
