import React, { useEffect, useState } from 'react';

import clsx from 'clsx';
import { motion } from 'framer-motion';
import Cookies from 'js-cookie';
import { useTranslations } from 'next-intl';

import { Button } from 'ui/button/button';

import { useTheme } from 'hooks/use-theme';

import IconClose from 'icons/common/burger-icon-close.svg';
import IconArrow from 'icons/common/chevron-up.svg';

import styles from './cookie-modal.module.scss';

export enum ICookieFrames {
  Main,
  Partners,
}

const variants = {
  closed: { opacity: 0 },
  open: {
    opacity: 1,
  },
};

const variantsText = {
  closed: { height: '0px' },
  open: {
    height: 'auto',
    marginBottom: 16,
  },
};

const variantsArrow = {
  closed: { rotate: 180 },
  open: {
    rotate: 0,
  },
};

const CookieModal: React.FC = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState<boolean>(false);
  const [isTextHidden, setIsTextHidden] = useState<boolean>(true);
  const t = useTranslations('common.cookie');

  const [cookieFrame, setCookieFrame] = useState<ICookieFrames>(
    ICookieFrames.Main
  );

  const { theme } = useTheme();

  useEffect(() => {
    setCookiesAccepted(
      !!localStorage.getItem('cookies_accepted') ||
        !!Cookies?.get('cookies_accepted')
    );
  }, []);

  //!!!!ACHTUNG!!!
  //Это ГЛОБАЛЬНОЕ подтверждение, оно унифицировано в каждом приложении
  //!!!Если меняешь здесь - поменяй во всех сервисах тоже!!!
  const handleButton = (): void => {
    const domain_name = 'cryptomus.com';

    localStorage.setItem('cookies_accepted', 'true');

    const expirationDate = new Date();

    expirationDate.setFullYear(expirationDate.getFullYear() + 10);

    if (window && window.location.host.includes(domain_name))
      Cookies.set('cookies_accepted', 'true', {
        domain: domain_name,
        expires: expirationDate,
      });
    else Cookies.set('cookies_accepted', 'true', { expires: expirationDate });
    setCookiesAccepted(true);
  };

  const getCookieFrame = {
    [ICookieFrames.Main]: (
      <>
        <div className={styles.header}>
          <div className={styles.header__title}>
            <h2>{t('Title')}</h2>
            <motion.div
              animate={isTextHidden ? 'closed' : 'open'}
              className={styles.toggleBtn}
              initial='closed'
              style={{ height: 24, width: 24 }}
              transition={{ duration: 0.3 }}
              variants={variantsArrow}
            >
              <IconArrow onClick={() => setIsTextHidden(!isTextHidden)} />
            </motion.div>
          </div>
          <IconClose className={styles.close__btn} onClick={handleButton} />
        </div>

        <div
          className={clsx(styles.text__wrapper, styles.text__wrapper_mobile)}
        >
          <motion.p
            animate={isTextHidden ? 'closed' : 'open'}
            className={styles.text}
            transition={{ duration: 0.3 }}
            variants={variantsText}
          >
            {t('Text')}
          </motion.p>
        </div>

        <div
          className={clsx(styles.text__wrapper, styles.text__wrapper_desktop)}
        >
          <p className={styles.text}>{t('Text')}</p>
        </div>

        <div className={styles.button_wrapper}>
          <Button appearance='primary' size='xs' onClick={handleButton}>
            {t('Confirm')}
          </Button>
          <Button
            appearance='gray'
            size='xs'
            onClick={() => {
              setCookieFrame(ICookieFrames.Partners);
              setIsTextHidden(false);
            }}
          >
            {t('Partners')}
          </Button>
        </div>
      </>
    ),
    [ICookieFrames.Partners]: (
      <>
        <div className={styles.header}>
          <div className={styles.header__title}>
            <h2>{t('Title')}</h2>
            <motion.div
              animate={isTextHidden ? 'closed' : 'open'}
              className={styles.toggleBtn}
              initial='closed'
              style={{ height: 24, width: 24 }}
              transition={{ duration: 0.3 }}
              variants={variantsArrow}
            >
              <IconArrow onClick={() => setIsTextHidden(!isTextHidden)} />
            </motion.div>
          </div>
          <IconClose className={styles.close__btn} onClick={handleButton} />
        </div>

        <div
          className={clsx(styles.text__wrapper, styles.text__wrapper_mobile)}
        >
          <motion.p
            animate={isTextHidden ? 'closed' : 'open'}
            className={styles.text}
            transition={{ duration: 0.3 }}
            variants={variantsText}
          >
            <p className={styles.text}>
              <a
                className={styles.link}
                href='https://www.nextroll.com/privacy'
                rel='noreferrer'
                target='_blank'
              >
                NextRoll
              </a>
              &nbsp;
              {t('NextRoll')}
            </p>
          </motion.p>
        </div>

        <div
          className={clsx(styles.text__wrapper, styles.text__wrapper_desktop)}
        >
          <p className={styles.text}>
            <p className={styles.text}>
              <a
                className={styles.link}
                href='https://www.nextroll.com/privacy'
                rel='noreferrer'
                target='_blank'
              >
                NextRoll
              </a>
              &nbsp;
              {t('NextRoll')}
            </p>
          </p>
        </div>

        <div className={styles.button_wrapper}>
          <Button appearance='primary' size='xs' onClick={handleButton}>
            {t('Confirm')}
          </Button>
          <Button
            appearance='gray'
            size='xs'
            onClick={() => {
              setCookieFrame(ICookieFrames.Main);
              setIsTextHidden(false);
            }}
          >
            {t('Back')}
          </Button>
        </div>
      </>
    ),
  };

  if (cookiesAccepted) {
    return null;
  }

  return (
    <motion.div
      animate='open'
      exit='closed'
      initial='closed'
      transition={{ duration: 0.3 }}
      variants={variants}
      className={clsx(styles.wrapper, {
        [styles.wrapper_dark]: theme === 'dark',
      })}
    >
      {getCookieFrame[cookieFrame]}
    </motion.div>
  );
};

export default CookieModal;
