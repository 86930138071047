import React, { useMemo, useState } from 'react';

import LinkItem from '../../../user-center-content/link-item/link-item';

import clsx from 'clsx';
import { Switch, useMediaQuery } from 'cryptomus-aurora-kit';
import { useLocale, useTranslations } from 'next-intl';

import BurgerHeader from 'components/header/burger-menu/burger-header/burger-header';
import type {
  ISubItem,
  mainMenuItem,
} from 'components/header/navigation/nav-list/nav-item/nav-item.types';
import LanguageSelector from 'components/selectors/language-selector';
import DropdownList from 'ui/dropdown-list/dropdown-list';

import { useTextDirection } from 'hooks/use-text-direction';
import { useTheme } from 'hooks/use-theme';
import { getLanguageFullName } from 'utils/get-language-full-name';

import GlobeIcon from 'public/icons/header/globe.svg';
import MoonIcon from 'public/icons/header/moon.svg';

import styles from './settings-menu-content.module.scss';

interface ISettingsMenuContentProps {
  closeMenu: () => void;
  burger?: boolean;
}

const SettingsMenuContent: React.FC<ISettingsMenuContentProps> = ({
  closeMenu,
  burger = false,
}) => {
  const t = useTranslations('layout.header');

  const direction = useTextDirection();

  const [openSubMenu, setOpenSubMenu] = useState<ISubItem | null>(null);

  const locale = useLocale();
  const localeFullName = getLanguageFullName(locale);

  const { theme, toggleTheme } = useTheme();

  const isTabletS = useMediaQuery('tabletS');

  const useCenterContentMain: mainMenuItem[] = [
    {
      el: (
        <LinkItem
          icon={<GlobeIcon />}
          subTitle={localeFullName}
          title={t('Language')}
        />
      ),
      subItem: {
        el: (
          <LanguageSelector
            inBurger={burger}
            maxHeight='576px'
            onChange={closeMenu}
          />
        ),
        title: t('Language'),
      },
    },
    {
      el: (
        <LinkItem
          icon={<MoonIcon />}
          subTitle={theme === 'dark' ? t('On') : t('Off')}
          title={t('Dark theme')}
          rightEl={
            <Switch
              aria-label={`Switch to ${theme === 'dark' ? 'light' : 'dark'} theme`}
              checked={theme === 'dark'}
              tabIndex={-1}
              onClick={toggleTheme}
            />
          }
          onClick={toggleTheme}
        />
      ),
    },
  ];

  const useCenterContent = useMemo(
    () =>
      burger
        ? [
            { el: <BurgerHeader reversed closeBurger={closeMenu} /> },
            ...useCenterContentMain,
          ]
        : useCenterContentMain,
    /* eslint-disable react-hooks/exhaustive-deps */
    [burger, useCenterContentMain, isTabletS]
  );

  return (
    <div className={clsx(styles.wrapper, { [styles.burger]: burger })}>
      <DropdownList
        burger={burger}
        direction={direction}
        mainLinks={useCenterContent}
        openSubMenuList={openSubMenu}
        setOpenSubMenuList={setOpenSubMenu}
      />
    </div>
  );
};

export default SettingsMenuContent;
