'use client';

import { createContext, useLayoutEffect, useState } from 'react';

import Cookies from 'js-cookie';
import type { FC, PropsWithChildren } from 'react';

type ITheme = 'dark' | 'light';

interface IThemeContext {
  setThemeDirectly: (theme: ITheme) => void;
  theme: ITheme;
  toggleTheme: () => void;
}

const domain_name = 'cryptomus.com';

const setThemeCookie = (theme: ITheme): void => {
  if (window.location.host.includes('cryptomus.com')) {
    Cookies.set('theme', theme, {
      domain: domain_name,
      expires: 400,
    });
  } else {
    Cookies.set('theme', theme, {
      expires: 400,
    });
  }
};

const getCookiesTheme = (): string | undefined =>
  Cookies.get('theme') as ITheme;

export const ThemeContext = createContext<IThemeContext>({
  setThemeDirectly: () => {},
  theme: 'light',
  toggleTheme: () => {},
});

const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [theme, setTheme] = useState<ITheme>('light');

  const setThemeDirectly = (theme: ITheme): void => {
    setTheme(theme);
    setThemeCookie(theme);
  };

  const toggleTheme = (): void => {
    if (theme === 'dark') {
      setThemeDirectly('light');
    } else {
      setThemeDirectly('dark');
    }
  };

  useLayoutEffect(() => {
    const cookieTheme = getCookiesTheme(); //Берем из кук тему

    if (cookieTheme) {
      setTheme(cookieTheme as ITheme); //Устанавливаем тему, взятую из кук

      if (theme === 'dark') {
        document.body.classList.add('dark');
        document.body.classList.remove('light');
      } else if (theme === 'light') {
        document.body.classList.add('light');
        document.body.classList.remove('dark');
      }
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ setThemeDirectly, theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
