'use client';

import clsx from 'clsx';
import type { ReactNode } from 'react';
import type { ToastContainerProps, TypeOptions } from 'react-toastify';
import { Slide, ToastContainer } from 'react-toastify';

import { useTextDirection } from 'hooks/use-text-direction';
import { useTheme } from 'hooks/use-theme';

import styles from './toasts-provider.module.scss';

import IconError from '/public/icons/common/alert-circle.svg';
import IconSuccess from '/public/icons/common/check-circle.svg';
import IconClose from '/public/icons/common/close.svg';
import IconInfo from '/public/icons/common/info.svg';
import IconWarning from '/public/icons/common/warning-icon.svg';

const iconByStatus: Record<TypeOptions, ReactNode> = {
  default: <IconInfo />,
  error: <IconError />,
  info: <IconInfo />,
  success: <IconSuccess />,
  warning: <IconWarning />,
};

export const ToastsProvider = (props: ToastContainerProps) => {
  const { theme } = useTheme();
  const textDirection = useTextDirection();
  const isRtl = textDirection === 'rtl';

  return (
    <ToastContainer
      draggable
      hideProgressBar
      pauseOnHover
      autoClose={3000}
      className={clsx(styles.container, styles[theme])}
      icon={({ type }) => iconByStatus[type]}
      limit={3}
      position={isRtl ? 'top-left' : 'top-right'}
      rtl={isRtl}
      theme={theme}
      transition={Slide}
      closeButton={({ closeToast, ariaLabel }) => (
        <button
          aria-label={ariaLabel}
          className={styles.closeButton}
          onClick={closeToast}
        >
          <IconClose />
        </button>
      )}
      {...props}
    />
  );
};
