import React from 'react';

import BurgerHeader from '../../../../burger-menu/burger-header/burger-header';
import { SupportItem } from './support-item/support-item';

import { useTranslations } from 'next-intl';

import MainIcon from 'public/icons/header/mail.svg';
import TelegramIcon from 'public/icons/header/Telegram.svg';
import TicketIcon from 'public/icons/header/ticket.svg';

import styles from './support-center.module.scss';

export interface ISupportItem {
  description: string;
  icon: JSX.Element;
  link: string;
  title: string;
  counter?: number;
  onSelect?: () => void;
  type?: 'internal' | 'external';
}

interface ISupportCenterProps {
  onSelect: () => void;
  burger?: boolean;
  closeMenu?: () => void;
  unread_tickets?: number;
}

const SupportCenter: React.FC<ISupportCenterProps> = ({
  onSelect,
  closeMenu,
  unread_tickets,
  burger = false,
}) => {
  const t = useTranslations('layout.header');

  const handleSelect = () => {
    onSelect?.();
    closeMenu?.();
  };

  const supportItemList: Omit<ISupportItem, 'onSelect'>[] = [
    {
      counter: unread_tickets,
      description: t('Rapid solutions via tickets'),
      icon: <TicketIcon />,
      link: `${process.env.CRYPTOMUS_APP_URL}/tickets`,
      title: t('My Tickets'),
    },
    {
      description: t('Get help with just an email'),
      icon: <MainIcon />,
      link: 'mailto:support@cryptomus.com',
      title: t('Send Email'),
      type: 'external',
    },
    {
      description: t('Support сhat on telegram'),
      icon: <TelegramIcon />,
      link: 'tg://resolve?domain=cryptomus_support_bot',
      title: 'Telegram',
      type: 'external',
    },
  ];

  return (
    <div className={styles.support}>
      {burger && closeMenu && <BurgerHeader reversed closeBurger={closeMenu} />}
      {supportItemList.map((item) => (
        <SupportItem key={item.title} {...item} onSelect={handleSelect} />
      ))}
    </div>
  );
};

export default SupportCenter;
