import { createNavigation } from 'next-intl/navigation';
import { defineRouting } from 'next-intl/routing';
import type { ReactNode } from 'react';

export interface ILayoutProps<T extends string = string> {
  children: ReactNode;
  params: { [_ in T]: string | string[] } & {
    locale: TLocale;
  };
}

export type TLocale = (typeof LOCALES)[number];

export const LOCALES = [
  'en',
  'es',
  'de',
  'fr',
  'tr',
  'ru',
  'uk',
  'zh',
  'uz',
  'kk',
  'pl',
  'ar',
  'ko',
  'ja',
  'pt',
  'fa',
  'pa',
] as const;

export const navigationRouting = defineRouting({
  defaultLocale: 'en',
  localeCookie: {
    domain: process.env.MAIN_DOMAIN || 'localhost',
    maxAge: 60 * 60 * 24 * 365,
    name: 'i18next',
  },
  localePrefix: 'as-needed',
  locales: LOCALES,
});

export const { Link, redirect, usePathname, useRouter, getPathname } =
  createNavigation(navigationRouting);
