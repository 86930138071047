import { useState } from 'react';

import {
  autoUpdate,
  flip,
  limitShift,
  offset,
  shift,
  useDismiss,
  useFloating,
} from '@floating-ui/react';
import clsx from 'clsx';
import { motion } from 'framer-motion';

import DownloadAppButton from 'ui/download-app-button/download-app-button';

import { useTextDirection } from 'hooks/use-text-direction';

import QRCodeIcon from 'icons/main/qr-code-icon.svg';

import styles from './qr-download-btn.module.scss';

const QrDownloadBtn = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dir = useTextDirection();
  const isRtl = dir === 'rtl';

  const { refs, floatingStyles, update, context } = useFloating({
    middleware: [
      offset(10),
      flip(),
      shift({
        limiter: limitShift({ offset: 10 }),
        padding: 16,
      }),
    ],
    onOpenChange: setIsOpen,
    open: isOpen,
    placement: 'bottom',
    strategy: 'absolute',
    whileElementsMounted: autoUpdate,
  });

  useDismiss(context);

  return (
    <>
      <button
        ref={refs.setReference}
        className={styles.icon}
        onClick={() => {
          setIsOpen(!isOpen);
          update();
        }}
      >
        <QRCodeIcon />
        {isOpen && (
          <motion.div
            ref={refs.setFloating}
            animate='open'
            className={clsx(styles.qr, { [styles.qr_ar]: isRtl })}
            exit='close'
            initial='close'
            transition={{ duration: 0.3 }}
            style={{
              ...floatingStyles,
            }}
            variants={{
              close: { opacity: 0 },
              open: { opacity: 1 },
            }}
          >
            <DownloadAppButton />
          </motion.div>
        )}
      </button>
    </>
  );
};

export default QrDownloadBtn;
