'use client';

import { messageFallbackHandler } from 'app/providers/i18n/lib/message-fallback-handler';
import type { AbstractIntlMessages, Timezone } from 'next-intl';
import { NextIntlClientProvider } from 'next-intl';
import type { ReactNode } from 'react';

interface I18nClientProviderProps {
  children: ReactNode;
  locale: string;
  messages: AbstractIntlMessages;
  now: Date;
  timeZone: Timezone | undefined;
}

export default function I18nClientProvider({
  locale,
  timeZone,
  now,
  children,
  messages,
}: I18nClientProviderProps) {
  return (
    <NextIntlClientProvider
      locale={locale}
      messages={messages}
      now={now}
      timeZone={timeZone}
      getMessageFallback={(info) =>
        messageFallbackHandler({ ...info, messages })
      }
      onError={() => {}}
    >
      {children}
    </NextIntlClientProvider>
  );
}
