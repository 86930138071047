import React from 'react';

import SettingsMenu from './settings-menu/settings-menu';

import { Button } from 'cryptomus-aurora-kit';
import { useTranslations } from 'next-intl';

import styles from './unauth-items.module.scss';

export interface UnAuthItemsProps {}

const UnAuthItems: React.FC<UnAuthItemsProps> = () => {
  const t = useTranslations('layout.header');

  return (
    <div className={styles.un_auth_items__wrapper}>
      <Button
        appearance='primary'
        as='a'
        className={styles.get_started_button}
        href={`${process.env.CRYPTOMUS_APP_URL}/signup`}
        size='s'
        // eslint-disable-next-line jsx-a11y/tabindex-no-positive
        tabIndex={1}
        type='internal'
      >
        {t('Get started')}
      </Button>
      <div className={styles.divider} />
      <SettingsMenu />
    </div>
  );
};

export default UnAuthItems;
