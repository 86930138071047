import React, { useCallback, useMemo, useState } from 'react';

import type { INavListDataItem } from '../../../header.types';
import { NavEl } from './nav-el/nav-el';
import type { ISubItem, mainMenuItem } from './nav-item.types';

import clsx from 'clsx';
import { DropdownMenu, Typography } from 'cryptomus-aurora-kit';
import { useTranslations } from 'next-intl';

import DropdownList from 'ui/dropdown-list/dropdown-list';

import { useTextDirection } from 'hooks/use-text-direction';

import ChevronDown from 'icons/common/chevron-down.svg';

import styles from './nav-item.module.scss';

interface INavItemProps {
  title: string;
  items?: INavListDataItem;
}

const NavItem: React.FC<INavItemProps> = ({ title, items }) => {
  const t = useTranslations('layout.header');

  const dir = useTextDirection();

  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const [openSubMenuLinks, setOpenSubMenuLinks] = useState<ISubItem | null>(
    null
  );

  const handleCloseMenu = useCallback(() => setOpenMenu(false), []);

  const navRows: mainMenuItem[] | undefined = useMemo(
    () =>
      items?.map((item) => ({
        el: (
          <NavEl
            key={item.title}
            description={item?.description ? t(item.description) : undefined}
            hasChildren={!!item?.subItems}
            icon={item.icon}
            link={item.link}
            title={item.title.length > 4 ? t(item.title) : item.title}
            onClick={item?.subItems ? undefined : handleCloseMenu}
          />
        ),
        subItem: item?.subItems
          ? {
              el: (
                <div className={clsx(styles.nav, styles.sub)}>
                  {item.subItems.map((subItem) => (
                    <NavEl
                      key={subItem.title}
                      icon={subItem.icon}
                      link={subItem.link}
                      description={
                        subItem?.description
                          ? t(subItem.description)
                          : undefined
                      }
                      title={
                        subItem.title.length > 4
                          ? t(subItem.title)
                          : subItem.title
                      }
                      onClick={handleCloseMenu}
                    />
                  ))}
                </div>
              ),
              title: item.title.length > 4 ? t(item.title) : item.title,
            }
          : undefined,
        title: item.title.length > 4 ? t(item.title) : item.title,
      })),
    [t, items, handleCloseMenu]
  );

  return (
    <DropdownMenu
      isOpenOnHover
      isOpen={openMenu}
      setIsOpen={setOpenMenu}
      width={364}
      parent={() => (
        <button
          className={clsx(styles.nav_title, { [styles.open]: openMenu })}
          // eslint-disable-next-line jsx-a11y/tabindex-no-positive
          tabIndex={1}
        >
          <Typography as='p' fontWeight={500} variant='system_h4'>
            {title === 'DeFi' ? title : t(title)}
          </Typography>
          <ChevronDown className={styles.nav_arrow} />
        </button>
      )}
      onCloseEvent={() => setOpenSubMenuLinks(null)}
    >
      <DropdownList
        direction={dir}
        mainLinks={navRows}
        openSubMenuList={openSubMenuLinks}
        setOpenSubMenuList={setOpenSubMenuLinks}
      />
    </DropdownMenu>
  );
};

export default NavItem;
