import React from 'react';
import Link from 'next/link';

import NavItem from './nav-item/nav-item';

import { FloatingDelayGroup } from '@floating-ui/react';
import clsx from 'clsx';
import { Typography } from 'cryptomus-aurora-kit';
import { useLocale, useTranslations } from 'next-intl';

import { HEADER_NAV_LIST } from 'components/header/header.data';
import type { IHeaderAdaptiveBasicProps } from 'components/header/header.types';

import { getLocaleLink } from 'utils/get-locale-link';

import styles from './nav-list.module.scss';

const NavList: React.FC<IHeaderAdaptiveBasicProps> = ({
  isLongLang = false,
  isAuthorized,
}) => {
  const t = useTranslations('layout.header');

  const locale = useLocale();

  return (
    <ul className={clsx(styles.nav_list, { [styles.long_lang]: isLongLang })}>
      <FloatingDelayGroup delay={{ close: 300, open: 100 }}>
        {HEADER_NAV_LIST.map((item) => {
          if (item.items) {
            return (
              <li key={item.title} tabIndex={-1}>
                <NavItem key={item.title} {...item} />
              </li>
            );
          }

          return (
            <li key={item.title} className={styles.list__item}>
              <Link
                className={styles.other_links}
                href={getLocaleLink(item.link ?? '', locale)}
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex={1}
              >
                <Typography as='span' fontWeight={500} variant='system_h4'>
                  {t(item.title)}
                </Typography>
                {isAuthorized && item.tag && (
                  <Typography
                    as='span'
                    className={styles.tag}
                    fontWeight={500}
                    variant='tag'
                  >
                    20%
                  </Typography>
                )}
              </Link>
            </li>
          );
        })}
      </FloatingDelayGroup>
    </ul>
  );
};

export default NavList;
