'use client';

import BurgerMenu from './burger-menu';
import HeaderLogo from './header-logo/header-logo';
import ControlList from './navigation/control-list';
import LinksMenu from './navigation/links-menu';
import NavList from './navigation/nav-list';

import clsx from 'clsx';
import { useLocale } from 'next-intl';
import type { FC } from 'react';

import { useAuth } from 'services/query-hooks/use-auth';

import styles from './header.module.scss';

interface IHeaderProps {}

const Header: FC<IHeaderProps> = () => {
  const { isAuth } = useAuth();
  const locale = useLocale();

  const isLongLang = () =>
    ['de', 'fr', 'ru', 'uk', 'kk', 'pl', 'pt', 'tr', 'es', 'uz'].includes(
      locale
    );

  return (
    <header className={styles.header}>
      <div
        className={clsx(styles.header_container, {
          [styles.long_lang]: isLongLang(),
        })}
      >
        <nav className={styles.header__main_nav}>
          <BurgerMenu isAuthorized={isAuth} isLongLang={isLongLang()} />
          <div
            className={clsx(styles.divider, styles.tablet, {
              [styles.long_lang]: isLongLang(),
            })}
          />

          <HeaderLogo />
          <LinksMenu isLongLang={isLongLang()} />
          <NavList isAuthorized={isAuth} isLongLang={isLongLang()} />
        </nav>
        <ControlList isAuth={isAuth} />
      </div>
    </header>
  );
};

export default Header;
