import { useLocale } from 'next-intl';

import { RTL_LANGUAGES } from 'constants/rtl-languages';

type TTextDirection = 'rtl' | 'ltr';

type TUseTextDirection = () => TTextDirection;

export const useTextDirection: TUseTextDirection = () => {
  const locale = useLocale();

  return RTL_LANGUAGES.includes(locale) ? 'rtl' : 'ltr';
};
