'use client';

import { ProgressProvider } from '@bprogress/next/app';
import type { PropsWithChildren } from 'react';

export const CustomNProgressProvider = ({ children }: PropsWithChildren) => (
  <ProgressProvider
    shallowRouting
    color='#fff'
    height='2px'
    options={{ showSpinner: false }}
  >
    {children}
  </ProgressProvider>
);
