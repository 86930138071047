import React from 'react';

import clsx from 'clsx';
import { CounterBadge, Typography } from 'cryptomus-aurora-kit';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslations } from 'next-intl';

import ReadAllIcon from 'public/icons/header/Check-Circle.svg';
import CloseIcon from 'public/icons/header/Close.svg';

import styles from './notification-center-header.module.scss';

interface INotificationCenterHeaderProps {
  isLoading: boolean;
  cancel?: () => void;
  closeMenu?: () => void;
  nothingFound?: boolean;
  readAll?: () => void;
  selectedCount?: number;
  unreadCount?: number;
}

const variants = {
  hidden: { opacity: 0, transition: { duration: 0.1, type: 'tween' } },
  visible: { opacity: 1, transition: { duration: 0.1, type: 'tween' } },
};

const motionProps = {
  animate: 'visible',
  exit: 'hidden',
  initial: 'hidden',
  variants,
};

const NotificationCenterHeader: React.FC<INotificationCenterHeaderProps> = ({
  readAll,
  closeMenu,
  nothingFound,
  selectedCount = 0,
  unreadCount = 0,
  isLoading = false,
}) => {
  const t = useTranslations('layout.header');

  const getDefaultTitle = () => {
    if (selectedCount) return null;

    return (
      <motion.div {...motionProps} key='default'>
        <Typography as='p' variant='button'>
          {t('Notifications')}
        </Typography>
      </motion.div>
    );
  };

  const getUnreadCounter = () => {
    if (!unreadCount) return null;

    return (
      <motion.div {...motionProps} key='unread count'>
        <CounterBadge counter={unreadCount} />
      </motion.div>
    );
  };

  const getReadAllBtn = () => {
    if (selectedCount || !unreadCount || nothingFound) return null;

    return (
      <motion.button
        {...motionProps}
        key='read all'
        className={clsx(styles.header__readAll_btn, 'customFocus')}
        disabled={unreadCount === 0 || isLoading}
        onClick={readAll}
      >
        <ReadAllIcon />
        <Typography
          as='span'
          className={styles.readAll_btn__text}
          fontWeight={500}
          variant='button'
        >
          {t('Read all')}
        </Typography>
      </motion.button>
    );
  };

  const getCloseBtn = () => {
    if (!closeMenu) return null;

    return (
      <button
        className={clsx(styles.header__close_btn, 'customFocus')}
        onClick={closeMenu}
      >
        <CloseIcon />
      </button>
    );
  };

  return (
    <div className={styles.header}>
      <AnimatePresence mode='wait'>
        {getDefaultTitle()}
        {getUnreadCounter()}
        <div className={styles.header__actions}>
          {getReadAllBtn()}
          {getCloseBtn()}
        </div>
      </AnimatePresence>
    </div>
  );
};

export default NotificationCenterHeader;
