'use client';

import { useEffect } from 'react';

import { EventEmitter } from 'events';

export const GlobalApiEventEmitterInitializer = () => {
  useEffect(() => {
    if (typeof window === 'undefined') return;

    window.apiEventEmitter = new EventEmitter();
  }, []);

  return null;
};
