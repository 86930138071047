'use client';

import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'next/navigation';

import type { TLocale } from 'app/providers/i18n/i18n.config';
import {
  LOCALES,
  usePathname,
  useRouter,
} from 'app/providers/i18n/i18n.config';
import { SelectorSearch } from 'cryptomus-aurora-kit';
import dayjs from 'dayjs';
import { useLocale, useTranslations } from 'next-intl';

import LanguageItem from 'components/selectors/language-selector/language-item/language-item';

import { useGetSelectorHeightInBurger } from 'hooks/use-get-selector-height-in-burger';
import { changeGlobalLanguage } from 'utils/change-global-language';
import { getLanguageFullName } from 'utils/get-language-full-name';

interface IDayjsImports {
  [key: string]: Promise<any>;
}

export const dayjsImports: IDayjsImports = {
  ar: import('dayjs/locale/ar'),
  de: import('dayjs/locale/de'),
  en: import('dayjs/locale/en'),
  es: import('dayjs/locale/es'),
  fa: import('dayjs/locale/fa'),
  fr: import('dayjs/locale/fr'),
  ja: import('dayjs/locale/ja'),
  kk: import('dayjs/locale/kk'),
  ko: import('dayjs/locale/ko'),
  pa: import('dayjs/locale/pa-in'),
  pl: import('dayjs/locale/pl'),
  pt: import('dayjs/locale/pt'),
  ru: import('dayjs/locale/ru'),
  tr: import('dayjs/locale/tr'),
  uk: import('dayjs/locale/uk'),
  uz: import('dayjs/locale/uz'),
  zh: import('dayjs/locale/zh'),
};

interface ILanguageSelectorProps {
  inBurger?: boolean;
  maxHeight?: string;
  onChange?: () => void;
}

const LanguageSelector: React.FC<ILanguageSelectorProps> = ({
  onChange,
  maxHeight,
  inBurger = false,
}) => {
  const t = useTranslations('layout.header');
  const locale = useLocale();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const router = useRouter();

  const searchParamsObject = Object.fromEntries(searchParams.entries());

  const [value, setValue] = useState<TLocale>(locale as TLocale);
  const [searchValue, setSearchValue] = useState<string>('');

  const filteredOptionsList: TLocale[] =
    searchValue === ''
      ? [...LOCALES]
      : ([...LOCALES].filter((elem) =>
          getLanguageFullName(elem)
            ?.toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase())
        ) as TLocale[]);

  // const { changeDefaultLanguage } = useLanguageChange(); //ChangeDefaultLanguage Query

  const handleChange = (lng_code: TLocale) => {
    // changeDefaultLanguage(lng_code); //TODO DEFAULT LANG
    changeGlobalLanguage(lng_code);
    dayjsImports[lng_code].then(() => {
      dayjs.locale(lng_code);
    });
    setValue(lng_code);
    onChange?.();
    router.push(
      { pathname, query: searchParamsObject },
      { locale: lng_code, scroll: false }
    );
  };

  const selectorHeight = useGetSelectorHeightInBurger();

  useEffect(() => {
    dayjsImports[locale].then(() => {
      dayjs.locale(locale);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SelectorSearch<TLocale>
      items={filteredOptionsList}
      maxHeight={inBurger ? selectorHeight : maxHeight}
      notFoundText={t('Nothing found')}
      searchPlaceHolder={t('Search')}
      searchValue={searchValue}
      selectedItem={value}
      setSearchValue={setSearchValue}
      renderItem={(item: TLocale) => (
        <LanguageItem
          icon={`/icons/flags/flag-${item}.svg`}
          title={getLanguageFullName(item)}
          onChange={() => handleChange(item)}
        />
      )}
      translations={{
        notFoundDefault: t('Nothing found'),
        searchDefault: t('Search'),
      }}
    />
  );
};

export default LanguageSelector;
