'use client';

import { useSearchParams } from 'next/navigation';

import Cookies from 'js-cookie';
import type { PropsWithChildren } from 'react';

const domain_name = 'cryptomus.com';

const RefCookieProvider = ({ children }: PropsWithChildren) => {
  const searchParams = useSearchParams();

  const refQuery = searchParams?.get('ref');

  if (refQuery) {
    const date = new Date();

    date.setMinutes(date.getMinutes() + 30);

    if (window.location.host.includes(domain_name)) {
      Cookies.set('ref', refQuery, { domain: domain_name, expires: date });
    } else Cookies.set('ref', refQuery, { expires: date });
  }

  return <>{children}</>;
};

export default RefCookieProvider;
