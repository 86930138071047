import React, { useRef } from 'react';

import KycStatusBadge from './kyc-status-badge/kyc-status-badge';

import clsx from 'clsx';
import { Skeleton, Typography } from 'cryptomus-aurora-kit';
import { useTranslations } from 'next-intl';

import UserAvatar from 'ui/user-avatar/user-avatar';

import { useGetUserSettings } from 'services/query-hooks/use-get-user-settings';
import { useCopyToClipboard } from 'hooks/use-copy-to-clipboard';

import CopyIcon from 'public/icons/header/Copy_new.svg';

import styles from './user-header.module.scss';

interface IUserHeaderProps {
  login: string;
  uid: string;
  avatar?: string;
  className?: string;
}

const UserHeader: React.FC<IUserHeaderProps> = ({
  avatar,
  login,
  uid,
  className,
}) => {
  const t = useTranslations('common');

  const copyRef = useRef<HTMLElement>(null);

  const [copyText, hasClipboardApi] = useCopyToClipboard(
    copyRef,
    t('UID successfully copied'),
    uid
  );

  const { data: userSettings, isLoading } = useGetUserSettings();

  return (
    <div className={clsx(styles.user, className)}>
      <UserAvatar alt='user avatar' login={login} size='big' src={avatar} />
      <div className={styles.user__info}>
        <Typography as='p' fontWeight={500} variant='system_h4'>
          {login}
        </Typography>
        <Typography
          as='span'
          className={styles.user__uid}
          fontWeight={400}
          variant='system_h5'
        >
          UID ·{' '}
          <span ref={copyRef}>
            {uid.replace(/^(.{8})(.*)(.{3})$/, '$1...$3')}
          </span>{' '}
          {hasClipboardApi ? (
            <button
              className='customFocus'
              tabIndex={0}
              onClick={() => copyText()}
            >
              <CopyIcon />
            </button>
          ) : null}
        </Typography>
        {isLoading ? (
          <Skeleton className={styles?.kyc_skeleton} />
        ) : (
          <KycStatusBadge
            status={userSettings?.data.result.kyc_status ?? 'not_passed'}
          />
        )}
      </div>
    </div>
  );
};

export default UserHeader;
