import type { AbstractIntlMessages } from 'next-intl';
import type { IntlError } from 'use-intl';

interface messageFallbackHandlerProps {
  error: IntlError;
  key: string;
  messages: AbstractIntlMessages;
  namespace?: string;
}

export const messageFallbackHandler = ({
  key,
  namespace,
  error,
  messages,
}: messageFallbackHandlerProps) => {
  const common = messages.common as AbstractIntlMessages;

  if (common?.[key]) {
    return common[key] as string;
  }

  console.error(error);

  return namespace ? `${namespace}.${key}` : key;
};
