import React, { useEffect, useState } from 'react';

import clsx from 'clsx';

import { useDebounceCallback } from 'hooks/use-debounce-callback';

import ButtonUpIcon from 'icons/common/button-up.svg';

import styles from './button-up.module.scss';

export const ButtonUp = (): JSX.Element => {
  const [visible, setIsVisible] = useState<boolean>(false);

  const toggleVisibility = useDebounceCallback(
    () => setIsVisible(window.scrollY > 500),
    100
  );

  const scrollToTop = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <button
      aria-label='Scroll to top button'
      className={clsx(styles.buttonUp_container, {
        [styles.buttonUp_visible]: visible,
      })}
      onClick={scrollToTop}
    >
      <ButtonUpIcon />
    </button>
  );
};
