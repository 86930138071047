import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import Cookies from 'js-cookie';

import { api } from 'services/api';

export interface IUseAuthData {
  result: {
    expired_at: string;
    status: boolean;
    token: string;
  };
  state: number;
}

export const useAuth = () => {
  const token: string | undefined = Cookies.get('session_id');
  const [isAuth, setIsAuth] = useState<boolean>(false);

  const { data, isSuccess, status } = useQuery({
    enabled: !!token,
    queryFn: () =>
      api.post<IUseAuthData>('v1/login/verify-token', {
        token,
      }),
    queryKey: ['verifyToken'],
  });

  useEffect(() => {
    if (typeof window === 'undefined') return;

    window?.apiEventEmitter?.on('logout', () => {
      setIsAuth(false);
    });
  }, []);

  useEffect(() => {
    setIsAuth(isSuccess && data?.data.result.status);
  }, [isSuccess, data?.data.result.status]);

  return { isAuth, status };
};
