import React from 'react';
import Link from 'next/link';

import clsx from 'clsx';
import { useLocale } from 'next-intl';

import IconLogo from 'icons/common/logo-cryptomus.svg';

import styles from './logo.module.scss';

const Logo: React.FC<{ className?: string }> = ({ className }) => {
  const locale = useLocale();

  return (
    <Link
      aria-label='Cryptomus main page'
      className={clsx(styles.logo, className)}
      href='/'
      lang={locale}
    >
      <IconLogo />
    </Link>
  );
};

export default Logo;
