import React, { useEffect } from 'react';

import { useLocale } from 'next-intl';

interface IProps {
  className: string;
}

const CertikButton: React.FC<IProps> = ({ className }) => {
  const locale = useLocale();

  const insertScript = (locale: string) => {
    const existingScript = document.querySelector(
      '#crt-snippet'
    ) as HTMLScriptElement;

    if (existingScript) {
      existingScript.src = `https://emblem.certik-assets.com/script?pid=cryptomus&vid=eea4197d&locale=${locale}`;
    } else {
      const script = document.createElement('script');

      script.id = 'crt-snippet';
      script.src = `https://emblem.certik-assets.com/script?pid=cryptomus&vid=eea4197d&locale=${locale}`;

      document.body.append(script);
    }
  };

  useEffect(() => {
    insertScript(locale ?? 'en');

    return () => {
      const script = document.querySelector('#crt-snippet');

      script?.remove();
    };
  }, [locale]);

  return (
    <div className={className}>
      <div className='certik-emblem' data-id='eea4197d'>
        <a href='https://skynet.certik.com/projects/cryptomus?utm_source=SkyEmblem&utm_campaign=cryptomus&utm_medium=link'>
          View project at certik.com
        </a>
      </div>
    </div>
  );
};

export default CertikButton;
