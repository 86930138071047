import type { RefObject } from 'react';
import { toast } from 'react-toastify';

type CopyFn = () => void;

export const useCopyToClipboard = (
  ref: RefObject<HTMLElement>,
  message: string,
  textToCopy?: string
): [CopyFn, boolean] => {
  const navigator =
    typeof window === 'undefined' ? undefined : window?.navigator;

  const hasClipboardApi = !!navigator?.clipboard?.writeText;

  const copyText: CopyFn = () => {
    if (hasClipboardApi && textToCopy) {
      navigator.clipboard.writeText(textToCopy || '');
      toast.success(message);
    } else if (hasClipboardApi && ref?.current?.textContent !== '') {
      navigator.clipboard.writeText(ref?.current?.textContent || '');
      toast.success(message);
    }
  };

  return [copyText, hasClipboardApi];
};
