'use client';

import React from 'react';
import Link from 'next/link';

import clsx from 'clsx';
import { IconWrapper, Typography } from 'cryptomus-aurora-kit';
import { useLocale } from 'next-intl';

import type { ILink } from 'components/header/header.types';

import { getLocaleLink } from 'utils/get-locale-link';

import styles from './burger-menu-item.module.scss';

interface IBurgerMenuItemProps {
  hasChildren: boolean;
  title: string;
  description?: string;
  icon?: JSX.Element;
  link?: ILink;
  onClick?: () => void;
}

const BurgerMenuItem: React.FC<IBurgerMenuItemProps> = ({
  icon,
  title,
  description,
  link,
  hasChildren,
  onClick,
}) => {
  const locale = useLocale();

  const getEl = () => (
    <>
      {icon ? (
        <IconWrapper
          appearance='select'
          className={styles.item__icon}
          size='big'
        >
          {icon}
        </IconWrapper>
      ) : null}
      <div className={styles.item__content}>
        <Typography as='p' fontWeight={500} variant='system_h4'>
          {title}
        </Typography>
        {description ? (
          <Typography as='span' fontWeight={400} variant='system_h5'>
            {description}
          </Typography>
        ) : null}
      </div>
    </>
  );

  if (link?.type === 'internal')
    return (
      <Link
        className={styles.item}
        href={link.href}
        tabIndex={0}
        onClick={() => onClick?.()}
      >
        {getEl()}
      </Link>
    );

  if (link?.type === 'external')
    return (
      <a
        className={styles.item}
        href={getLocaleLink(link.href, locale)}
        tabIndex={0}
        onClick={() => onClick?.()}
      >
        {getEl()}
      </a>
    );

  return (
    <button
      className={clsx(styles.item, { [styles.with_child]: hasChildren })}
      tabIndex={0}
      onClick={(e) => {
        e.preventDefault();
        onClick?.();
      }}
    >
      {getEl()}
    </button>
  );
};

export default BurgerMenuItem;
