import Link from 'next/link';

import type { ILink, INavListItem } from '../../../header.types';

import { IconWrapper, Typography } from 'cryptomus-aurora-kit';
import { useLocale } from 'next-intl';
import type { FC } from 'react';

import { getLocaleLink } from 'utils/get-locale-link';

import styles from './menu-item.module.scss';

interface IMenuItemProps extends INavListItem {
  onSelect: () => void;
  icon?: JSX.Element;
  link?: ILink;
}

export const MenuItem: FC<IMenuItemProps> = ({
  title,
  description,
  link,
  icon,
  onSelect,
}) => {
  const locale = useLocale();

  const linkHref =
    link?.type === 'internal'
      ? link.href
      : getLocaleLink(link?.href ?? '', locale);

  return (
    <Link className={styles.links__item} href={linkHref} onClick={onSelect}>
      {icon && (
        <IconWrapper
          appearance='select'
          className={styles.item__icon}
          size='medium'
        >
          {icon}
        </IconWrapper>
      )}
      <div className={styles.item__content}>
        <Typography as='p' fontWeight={500} variant='system_h4'>
          {title}
        </Typography>
        <Typography
          as='span'
          className={styles.item__content__desc}
          fontWeight={400}
          variant='system_h5'
        >
          {description}
        </Typography>
      </div>
    </Link>
  );
};
