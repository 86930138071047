import React from 'react';
import Link from 'next/link';

import clsx from 'clsx';
import { Typography } from 'cryptomus-aurora-kit';
import { useTranslations } from 'next-intl';

import type { KYCStatus } from 'services/user.service';

import AlertIcon from 'public/icons/header/alert_circle.svg';
import NotPassIcon from 'public/icons/header/verification_not_pass.svg';
import PassIcon from 'public/icons/header/verification_pass.svg';
import ArrowRightIcon from 'icons/common/chevron-right-icon.svg';

import styles from './kyc-status-badge.module.scss';

interface IKYCStatusBadge {
  status: KYCStatus;
}

interface IKYCStepsLabelItem {
  icon: JSX.Element;
  title: string;
}

type KYCStepsLabel = Record<KYCStatus, IKYCStepsLabelItem>;

const kycStepsLabels: KYCStepsLabel = {
  approved: { icon: <PassIcon />, title: 'Approved' },
  in_progress: { icon: <AlertIcon />, title: 'In Progress' },
  not_passed: { icon: <AlertIcon />, title: 'Not Passed' },
  rejected: { icon: <NotPassIcon />, title: 'Rejected' },
};

const KycStatusBadge: React.FC<IKYCStatusBadge> = ({ status }) => {
  const t = useTranslations('layout.header');

  const getBadge = () => (
    <div className={clsx(styles.kyc_badge, styles[status])}>
      <div className={styles.kyc_badge__icon}>
        {kycStepsLabels[status].icon}
      </div>
      <Typography as='p' fontWeight={500} variant='system_h5'>
        KYC {t(kycStepsLabels[status].title)}
      </Typography>
      {status === 'approved' ? null : (
        <ArrowRightIcon className={styles.badge__arrow} />
      )}
    </div>
  );

  if (status !== 'approved')
    return (
      <Link
        className={clsx(styles.kyc_wrapper, 'customFocus')}
        href='https://app.cryptomus.com/settings/kyc-verification'
      >
        {getBadge()}
      </Link>
    );

  return <div className={styles.kyc_wrapper}>{getBadge()}</div>;
};

export default KycStatusBadge;
