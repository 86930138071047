import { logout } from './auth.service';

import axios from 'axios';
import Cookies from 'js-cookie';

import type { IResponseErrors } from 'services/api.types';

const apiConfig = axios.create({
  // baseURL: "https://api-app.xclvkbdsf234lj34s.ru/",
  // @TODO Сделать зависимым от env переменных
  baseURL: 'https://api-app.cryptomus.com/',
  headers: {
    Authorization: `Bearer ${Cookies.get('session_id')}`,
  },
  timeout: 5000,
});

apiConfig.interceptors.response.use(
  (res) => res,
  (error: IResponseErrors) => {
    if (error?.status === 401) {
      logout();
    }
  }
);

export const api = apiConfig;

export const tradingViewApi = axios.create({
  baseURL: 'https://exchange-ws.cryptomus.com',
  timeout: 5000,
});

axios.create({
  baseURL: 'https://btc-api.cryptomus.com',

  headers: {
    Authorization: `Bearer ${Cookies.get('session_id')}`,
  },
  //убрать
  timeout: 5000,
});

axios.create({
  baseURL: 'https://tron-api.hopslomp123.ru/',

  headers: {
    Authorization: `Bearer ${Cookies.get('session_id')}`,
  },
  //убрать
  timeout: 5000,
});
