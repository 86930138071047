'use client';

import React from 'react';

import { useMediaQuery } from 'cryptomus-aurora-kit';
import { useTranslations } from 'next-intl';
import { QRCodeSVG } from 'qrcode.react';

import LogoIcon from 'icons/common/icon-cat.svg';

import styles from './download-app-button.module.scss';

const DownloadAppButton: React.FC = () => {
  const t = useTranslations('common');
  const isTablet = useMediaQuery('tablet');

  return (
    <div className={styles.qr_menu}>
      <h4>{t('Download App')}</h4>
      <div className={styles.qr_menu__qr}>
        <QRCodeSVG
          bgColor='transparent'
          fgColor='var(--primary)'
          level='Q'
          size={isTablet ? 130 : 157}
          value={`${process.env.BASE_URL}/download`}
        />
        <div className={styles.qr__logo}>
          <LogoIcon />
        </div>
      </div>
    </div>
  );
};

export default DownloadAppButton;
