'use client';

import React, { createContext, useCallback, useContext, useRef } from 'react';

import type { FC, ReactNode } from 'react';
import { ReCAPTCHA } from 'react-google-recaptcha';

interface RecaptchaContextType {
  execute: () => Promise<string | null>;
}

const RecaptchaContext = createContext<RecaptchaContextType | undefined>(
  undefined
);

export const RecaptchaV2Provider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const execute = useCallback(async () => {
    if (!recaptchaRef.current) {
      throw new Error('ReCAPTCHA is not initialized');
    }

    return new Promise<string | null>((resolve, reject) => {
      recaptchaRef?.current
        ?.executeAsync()
        .then(resolve)
        .catch(reject)
        .finally(() => recaptchaRef?.current?.reset());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recaptchaRef?.current]);

  return (
    <RecaptchaContext.Provider value={{ execute }}>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY_V2 || ''}
        size='invisible'
      />
      {children}
    </RecaptchaContext.Provider>
  );
};

export const useRecaptcha = () => {
  const context = useContext(RecaptchaContext);

  if (!context) {
    throw new Error('useRecaptcha must be used within a RecaptchaProvider');
  }

  return context;
};
